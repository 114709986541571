import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import ComplaintsComplianceNav from '../../../components/SubPageNav/ComplaintsComlianceNav'
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav'

const CodeOfEthicsPage = () => {
  const intl = useIntl()
  return (
    <Layout
      view='builder'
      breadcrumb={{
        text: <FormattedMessage id='builderNav.dashboard' />,
        path: '/builder-vendor/dashboard/'
      }}
      header={<FormattedMessage id='builderNav.complaints' />}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'builderNav.complaints' })}
      />
      <Container>
        <Row>
          <Col lg={3} md={3}>
            <ComplaintsComplianceNav activePath='/builder-vendor/complaints-compliance-enforcement/code-of-ethics/' />
          </Col>

          <Col>
            <Row>
              <Col>
                <h2>
                  <FormattedMessage id='daCommittee.headerB' />
                </h2>
                <p>
                  <a href={intl.formatMessage({ id: 'daCommittee.linkB' })}>
                    <FormattedMessage id='daCommittee.contentBi' />
                  </a>{' '}
                  <FormattedMessage id='daCommittee.contentBiii' />{' '}
                  <a href={intl.formatMessage({ id: 'daCommittee.linkD' })}>
                    <FormattedMessage id='daCommittee.contentBiv' />
                  </a>{' '}
                  <FormattedMessage id='daCommittee.contentBii' />{' '}
                  <a href={intl.formatMessage({ id: 'daCommittee.linkE' })}>
                    <FormattedMessage id='daCommittee.contentBv' />
                  </a>{' '}
                  <FormattedMessage id='daCommittee.contentBvi' />
                  <br />
                  <br />
                  <FormattedMessage id='daCommittee.contentBvii' />
                </p>

                <h2>
                  <FormattedMessage id='daCommittee.headerI' />
                </h2>
                <p>
                  <FormattedMessage id='daCommittee.contentI' />
                </p>
                <p>
                  <FormattedMessage id='daCommittee.contentKi' />{' '}
                  <Link to='/about/discipline-and-appeals-committees/'>
                    <FormattedMessage id='daCommittee.contentKii' />
                  </Link>{' '}
                  <FormattedMessage id='daCommittee.contentKiii' />
                  <ul>
                    <li>
                      <FormattedMessage id='daCommittee.list06a' />
                    </li>
                    <li>
                      <FormattedMessage id='daCommittee.list06b' />
                    </li>
                    <li>
                      <FormattedMessage id='daCommittee.list06c' />
                    </li>
                    <li>
                      <FormattedMessage id='daCommittee.list06d' />
                    </li>
                    <li>
                      <FormattedMessage id='daCommittee.list06e' />
                    </li>
                  </ul>
                </p>

                <h2>
                  <FormattedMessage id='daCommittee.headerJ' />
                </h2>
                <p>
                  <FormattedMessage id='daCommittee.contentJ' />{' '}
                  <FormattedMessage id='daCommittee.contentJi' />
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <SubPageBottomNav
              prevLink={{
                text: <FormattedMessage id='builderNav.complaints3' />,
                path:
                  '/builder-vendor/complaints-compliance-enforcement/compliance-enforcement/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default CodeOfEthicsPage
